<template>
  <div ref="main" class="m-main-wrap" v-loading="loadList">
    <div ref="topDom">
      <!-- 搜索 start -->
      <div class="flex-l lhx40">
        <el-input
          class="w240 mr10 mb20"
          placeholder="请输入用戶名稱/ID/郵箱賬戶"
          clearable
          v-model="filters.title"
          @clear="search"
          @keyup.enter.native="search"
        ></el-input>
        <el-date-picker
          class="mr10 mb20"
          v-model="time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
          @change="changeTime"
          @clear="search"
          clearable
        ></el-date-picker>
        <div>
          <el-button type="primary" @click="search">搜尋</el-button>
          <el-button type="primary" @click="exportUsers">導出</el-button>
        </div>
      </div>
    </div>
    <!-- 内部侧边栏 -->
    <el-container>
      <el-main>
        <!-- 表格 -->
        <el-table
          :data="tableData.data"
          style="width: 100%"
          :height="tableHeight"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-form label-position="top" inline>
                <el-form-item label="郵箱賬戶" class="mr20">
                  <span>
                    {{ props.row.email }}
                  </span>
                </el-form-item>
                <el-form-item label="手機號碼" class="mr20">
                  <span>{{ props.row.rid }}</span>
                </el-form-item>
                <el-form-item label="國家/區域" class="mr20">
                  <span>{{ props.row.region }}</span>
                </el-form-item>
                <el-form-item label="like商品數" class="mr20">
                  <span>{{ props.row.like }}</span>
                </el-form-item>
                <el-form-item label="Follow緊" class="mr20">
                  <span>{{ props.row.follow }}</span>
                </el-form-item>
                <el-form-item label="購買商品數" class="mr20">
                  <span>{{ props.row.b_goods | formatTime }}</span>
                </el-form-item>
                <el-form-item label="售出商品數" class="mr20">
                  <span>{{ props.row.s_goods }}</span>
                </el-form-item>
                <el-form-item label="收到評價" class="mr20">
                  <span>{{ props.row.e_num }}</span>
                </el-form-item>
                <el-form-item label="回復頻率" class="mr20">
                  <!-- 1.回复效率高，2.回复效率良好，3.不常回复 -->
                  <el-tag type="success" v-if="props.row.r_state == 1"
                    >回复效率高</el-tag
                  >
                  <el-tag v-if="props.row.r_state == 2">回复效率良好</el-tag>
                  <el-tag type="danger" v-if="props.row.r_state == 3"
                    >不常回复</el-tag
                  >
                </el-form-item>
                <el-form-item label="注册時間" class="mr20">
                  <span>{{ props.row.create_time | formatTime }}</span>
                </el-form-item>
                <el-form-item label="用戶標籤" class="mr20">
                  <el-button
                    type="primary"
                    size="small"
                    @click="openTag(props.row.id)"
                    >查看</el-button
                  >
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column width="60" label="NO.">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column label="用戶ID" width="120">
            <template slot-scope="scope"
              ><a
                class="el-link el-link--primary"
                target="_blank"
                :href="
                  constants.clientUrl + '/userDetails?userId=' + scope.row.id
                "
                >{{ scope.row.realname }}</a
              ></template
            >
          </el-table-column>
          <el-table-column label="用戶名稱" width="100">
            <template slot-scope="scope">{{ scope.row.nickname }}</template>
          </el-table-column>
          <el-table-column label="發佈商品數" width="120" prop="s_num" sortable>
            <template slot-scope="scope">
              {{ scope.row.s_num }}
            </template>
          </el-table-column>
          <el-table-column
            width="180"
            label="購買總金額（HK$）"
            prop="b_money"
            sortable
          ></el-table-column>
          <el-table-column
            width="180"
            label="售出總金額（HK$）"
            prop="s_money"
            sortable
          ></el-table-column>
          <el-table-column
            label="Followers"
            prop="b_follow"
            width="100"
          ></el-table-column>
          <el-table-column
            label="評價總星數"
            prop="stars"
            width="100"
          ></el-table-column>
          <el-table-column label="備註" width="100">
            <template slot-scope="scope">
              <el-popover
                v-if="scope.row.note"
                placement="top-start"
                title="備註"
                width="200"
                trigger="hover"
                :content="scope.row.note"
              >
                <div class="c-note" slot="reference">{{ scope.row.note }}</div>
              </el-popover>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column label="用戶狀態">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.state == 1">正常</el-tag>
              <el-tag type="danger" v-if="scope.row.state == 2">已封禁</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-link
                class="mr20"
                type="danger"
                v-if="scope.row.state == 1"
                @click="unPublish(scope.$index)"
                >封禁</el-link
              >
              <el-link
                class="mr20"
                type="danger"
                v-if="scope.row.state == 2"
                @click="publish(scope.$index)"
                >解封</el-link
              >
              <el-link
                type="primary"
                class="mr20"
                @click="openSetNote(scope.$index)"
                >備註</el-link
              >
              <a
                class="el-link el-link--primary"
                target="_blank"
                :href="
                  constants.clientUrl + '/userDetails?userId=' + scope.row.id
                "
                >查看用戶</a
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 ｜ 批量移动-->
        <div ref="btmDom" class="flex-r flex-mid pb10 pt10">
          <el-pagination
            background
            hide-on-single-page
            :page-size="tableData.per_page"
            :current-page="tableData.current_page"
            :total="tableData.total"
            @current-change="changePage"
          ></el-pagination>
        </div>
      </el-main>
    </el-container>
    <userTags
      @close="showDialog = false"
      :curId="curId"
      :show="showDialog"
    ></userTags>
    <userNoteVue
      :show="showNote"
      ref="userNote"
      type="edit"
      @close="showNote = false"
      :item="curItem"
      @confirm="confirmNote"
    ></userNoteVue>
  </div>
</template>
<script>
import { calTableHeight } from '@/common/tool'
import userTags from './components/userTags.vue'
import userNoteVue from './components/userNote.vue'
export default {
  components: { userTags, userNoteVue },
  data() {
    return {
      time: [],
      showDialog: false,
      loadList: false,
      curId: '',
      curItem: {},
      curIndex: -1,
      filters: {
        title: '',
        start_time: '',
        end_time: '',
      },
      tableData: {
        data: [],
        current_page: 1,
        per_page: 15,
        total: 0,
      },
      tableHeight: 100, //表格高度
      tags: [],
      showNote: false,
    }
  },
  created() {
    this.getDataList()
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = calTableHeight(this)
    })
  },
  methods: {
    openSetNote(index) {
      this.showNote = true
      this.curIndex = index
      this.curItem = this.tableData.data[index]
    },
    async confirmNote(data) {
      let res = await this.api.user.setNote(data.id, data.note)
      if (res && res.code == 0) {
        this.$message({
          message: '備註成功',
          type: 'success',
        })
        this.showNote = false
        this.getDataList(this.tableData.current_page)
      }
      this.loadList = false
    },
    async getDataList(page = 1) {
      this.loadList = true
      let res = await this.api.user.getUsers({ page, ...this.filters })
      if (res && res.code == 0) {
        this.tableData = res.data
      }
      this.loadList = false
    },
    async exportUsers() {
      let res = await this.api.user.exportData()
      var a = document.createElement('a')
      a.href = res.data
      document.body.append(a)
      a.click()
      document.body.removeChild(a)
    },
    changeTime(v) {
      if (Object.prototype.toString.call(v) == '[object Array]') {
        if (v.length > 0) {
          this.filters.start_time = v[0].getTime() / 1000
          this.filters.end_time = v[1].getTime() / 1000
        }
      } else {
        this.filters.start_time = ''
        this.filters.end_time = ''
      }
    },
    /**
     * @method: resetFilters 重置筛选条件
     */
    resetFilters() {
      this.filters = {
        title: '',
        start_time: '',
        end_time: '',
      }
    },
    search() {
      this.getDataList()
    },
    changePage(pageNum) {
      this.getDataList(pageNum)
    },
    unPublish(index) {
      this.$confirm(
        '封禁用戶後，用戶將不能訪問網站，您確定要封禁該用戶嗎？',
        '封禁用戶',
        {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          this.doUnPublish(index)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    publish(index) {
      this.$confirm(
        '解封用戶後，用戶將恢復正常狀態，您確定要解封該用戶嗎？',
        '解封用戶',
        {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          this.doPublish(index)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    async doUnPublish(index) {
      let id = this.tableData.data[index].id
      let res = await this.api.user.switchUser(id, 2)
      if (res && res.code == 0) {
        this.$set(this.tableData.data[index], 'state', 2)
        this.$message({
          message: '封禁成功',
          type: 'success',
        })
      }
    },
    async doPublish(index) {
      let id = this.tableData.data[index].id
      let res = await this.api.user.switchUser(id, 1)
      if (res && res.code == 0) {
        this.$set(this.tableData.data[index], 'state', 1)
        this.$message({
          message: '解封成功',
          type: 'success',
        })
      }
    },
    openTag(id) {
      this.curId = id
      this.showDialog = true
    },
  },
}
</script>
<style scoped>
/deep/.el-form-item__label {
  color: #999;
}
/deep/.el-form--inline .el-form-item {
  padding-right: 20px;
}
/deep/.el-table__fixed-body-wrapper {
  top: 59px !important;
}
</style>
