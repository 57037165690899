<!--
 * @Description: 
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2022-11-08 18:19:45
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-15 20:31:17
-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="40%"
    :modal-append-to-body="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div v-loading="loading">
      <el-form label-width="40px" :model="form">
        <el-form-item label="備註">
          <el-input
            type="textarea"
            v-model="form.note"
            placeholder="请输入備註"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()">取 消</el-button>
      <el-button type="primary" @click="confirm()">備 註</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "create",
    },
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      loading: false,
      title: "設置備註",
      showDialog: false,
      form: {
        note: "",
        id: "",
      },
    }
  },
  watch: {
    show(val) {
      this.showDialog = val
      // 打开弹窗的时候请求数据
      if (val) {
        this.form = {
          id: "",
          note: "",
        }
        if (this.type == "edit") {
          this.form = {
            id: this.item.id,
            note: this.item.note,
          }
        }
      }
    },
  },
  methods: {
    close() {
      this.$emit("close")
    },
    confirm() {
      this.$emit("confirm", this.form)
    },
  },
}
</script>
<style scoped></style>
