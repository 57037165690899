<!--
 * @Description: 
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2022-11-08 18:19:45
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-12-23 18:57:03
-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="50%"
    :modal-append-to-body="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div class="dialog-body" v-loading="loading">
      <el-table :data="tableData.data" style="width: 100%">
        <el-table-column label="標籤名稱" prop="title"></el-table-column>
        <el-table-column label="數量" prop="num"></el-table-column>
      </el-table>
      <el-pagination
        background
        hide-on-single-page
        :page-size="tableData.per_page"
        :current-page="tableData.current_page"
        :total="tableData.total"
        @current-change="changePage"
      ></el-pagination>
    </div>
    <!-- <span slot="footer" class="dialog-footer">
      <el-button @click="close()">取 消</el-button>
      <el-button type="primary" @click="confirm()">審 核</el-button>
    </span> -->
  </el-dialog>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    curId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      title: "用戶標籤",
      showDialog: false,
      tableData: {
        data: [],
        current_page: 1,
        per_page: 15,
        total: 0,
      },
    }
  },
  watch: {
    show(val) {
      this.showDialog = val
      // 打开弹窗的时候请求数据
      if (val) {
        //   console.log(val)
        this.getUserTags()
      }
    },
  },
  methods: {
    async getUserTags(page = 1) {
      let res = await this.api.user.getUserLabels(this.curId, page)
      if (res && res.code == 0) {
        this.tableData = res.data
      }
    },
    changePage(page) {
      this.getUserTags(page)
    },
    close() {
      this.$emit("close")
    },
    confirm() {
      this.close()
    },
  },
}
</script>
<style scoped></style>
